import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from 'src/app/helpers/utils.service';
import {EaHttpAbstract} from "../../../@ea/abstract/http.abstract";
import {map} from "rxjs/operators";
import {response} from "express";
import {STATUS_OK} from "../../app.constants";

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    private _url: string;
    isConnected: boolean = false;
    isConnectedSubject = new BehaviorSubject<boolean>(false);
    userDataSubject = new BehaviorSubject<any>(null);
    user : any;

    emitIsConnected(isConnected) {
        this.isConnectedSubject.next(isConnected);
    }
    emitDataUser(userData){
        this.userDataSubject.next(userData);
    }
    constructor(
        private _httpClient: HttpClient,
        private _utilsService : UtilsService,
        private _eaHttpAbstract: EaHttpAbstract) {
        this._url = environment.url_api;
        this.user = this._utilsService.getSession("userdata");
        if(!this.user){
            this.authenticated();
        } else {
            this.isConnected = true;
            this._utilsService.setSession('authenticated', this.isConnected);
            this.emitIsConnected(this.isConnected);
            this.emitDataUser(this.user);
        }


    }
    /**
   * sign-in
   *
   * @returns Promise<any>
   **/
    authentication(login, password): Observable<any> {
        const data = {
            login,
            password,
            type:'front'
        }
        return this._eaHttpAbstract.post("/security/auth/sign_in", data);
    }
    /**
    * user connected
    *
    * @returns Promise<any>
    **/
    authenticated(): Observable<any> {
        return this._eaHttpAbstract.post("/security/auth/authenticated", {})
            .pipe(
                map((response: any)=>{
                    if (response.status === STATUS_OK) {
                        this.isConnected = true;
                        this._utilsService.setSession('authenticated', true);
                        this.emitIsConnected(true);
                        this.getDataUser().subscribe(userData => {
                            this._utilsService.setSession('userdata', userData.data.data_user);
                            this.emitDataUser(userData.data.data_user)
                        });
                    }
                    else {
                        this.isConnected = false;
                        this._utilsService.setSession('authenticated', false);
                        this.emitIsConnected(false);
                        this._utilsService.setSession('userdata', null);
                        this.emitDataUser(null);
                    }
                    return response;
                })
            );
    }
    /**
     * logout
     *
     * @returns Promise<any>
    **/
    logout(): Observable<any> {
        return this._eaHttpAbstract.post("/security/auth/logout", {})
            .pipe(
                map((response: any)=>{
                    if (response.status === STATUS_OK) {
                        this._utilsService.removeSession("userdata");
                        this.isConnected = false;
                        this._utilsService.setSession('authenticated', false);
                        this.emitIsConnected(false);
                    }
                    return response;
                })
            );
    }

    /**
    * Get data user
    *
    * @returns Promise<any>
    **/
    getDataUser(): Observable<any> {
        return this._eaHttpAbstract.post("/security/auth/user_data", {})
            .pipe(
                map((response: any)=>{
                    return response;
                })
            );
    }
    /**
    * sign-up
    *
    * @returns Promise<any>
    **/
    sign_up(data: any): Promise<any> {
        /**
         * Params
         */
        let httpParams = new HttpParams();

        // httpParams = httpParams.append('Param_test', data.param_test);
        httpParams = httpParams.append('Param_nom', data.lastname);
        httpParams = httpParams.append('Param_prenom', data.firstname);
        httpParams = httpParams.append('Param_phone', data.mobile);
        // httpParams = httpParams.append('Param_iso2', data.CountryISO);
        httpParams = httpParams.append('Param_email', data.email);
        httpParams = httpParams.append('Param_password', data.password);


        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/public/InitInscription', httpParams,
                {
                    // headers: headers,
                    // params: httpParams,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    if (response.body.code === 1) {
                        resolve(response.body.data);
                    }
                    else
                        resolve([]);

                },
                    err => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    }, reject);

        });

    }

    /**
     * Forgot password
     *
     * @param contact
     * @param type
     */
    forgotPassword(contact: any, type): Observable<any> {
        return this._eaHttpAbstract
            .post("/security/forgot_password", { contact, type })
            .pipe(
                map((response: any) => {
                    //   if (response.body.status === STATUS_OK) {
                    return response;
                    //   }
                })
            );
    }


    /**
     * Code validation
     *
     */
    codeValidation(verification_code: string): Observable<any> {
        return this._eaHttpAbstract
            .post(`/security/verification_code`, { verification_code })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }


    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(new_password: string): Observable<any> {
        return this._eaHttpAbstract
            .post("/security/reset_password", { new_password })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**
    * Reset Password
    *
    * @returns Promise<any>
    **/
    // resetPassword(data): Promise<any> {
    //
    //     let headers = new HttpHeaders();
    //     headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    //
    //     /**
    //      * Params
    //      */
    //     let httpParams = new HttpParams();
    //     httpParams = httpParams.append('Param_contact', data.contact);
    //     httpParams = httpParams.append('Param_contactType', data.contactType); // Data.contactType
    //
    //
    //     return new Promise((resolve, reject) => {
    //
    //         this._httpClient.post(this._url + '/security/reset_password', httpParams,
    //             {
    //                 headers: headers,
    //                 responseType: 'json',
    //                 withCredentials: true,
    //                 observe: 'response'
    //             }).subscribe((response: any) => {
    //                 resolve(response);
    //             },
    //                 err => {
    //                     console.log('Error: ' + err.error);
    //                     console.log('Name: ' + err.name);
    //                     console.log('Message: ' + err.message);
    //                     console.log('Status: ' + err.status);
    //                 }, reject);
    //     });
    // }
    /**
    * Validated Code
    *
    * @returns Promise<any>
    **/
    // validateCode(data): Promise<any> {
    //     /**
    //      * Params
    //      */
    //     let httpParams = new HttpParams();
    //     httpParams = httpParams.append('Param_Code', data.codeValidation);
    //
    //     return new Promise((resolve, reject) => {
    //
    //
    //         this._httpClient.post(this._url + '/security/validate_code', httpParams,
    //             {
    //                 // headers: headers,
    //                 responseType: 'json',
    //                 withCredentials: true,
    //                 observe: 'response'
    //             }).subscribe((response: any) => {
    //                 if (response.body.code === 1) {
    //                     resolve(response.body.data);
    //                 }
    //                 else
    //                     resolve([]);
    //
    //             },
    //                 err => {
    //                     console.log('Error: ' + err.error);
    //                     console.log('Name: ' + err.name);
    //                     console.log('Message: ' + err.message);
    //                     console.log('Status: ' + err.status);
    //                 }, reject);
    //     });
    //
    // }
    /**
    *  Change Password
    *
    * @returns Promise<any>
    **/
    changePassword(data): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');


        /**
         * Params
         */
        let httpParams = new HttpParams();
        httpParams = httpParams.append('Param_NewPassword', data.password);

        return new Promise((resolve, reject) => {


            this._httpClient.post(this._url + '/security/updatePassword', httpParams,
                {
                    headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response);
                },
                    err => {
                        console.log('Error: ' + err.error);
                        console.log('Name: ' + err.name);
                        console.log('Message: ' + err.message);
                        console.log('Status: ' + err.status);
                    }, reject);
        });

    }
    initInscription(data: any): Observable<any> {
        const _data = {
            civility: data.genre,
            first_name: data.firstname,
            last_name: data.lastname,
            phone: data.phone,
            gsm: data.mobile,
            country: "Maroc",//todo
            iso2: "ma",//todo
            prefix: data.prefix,//todo
            email: data.email,
            password: data.password,
        };
        return this._eaHttpAbstract.post("/security/register_new_patient", _data).pipe(
            map(response=>{
                return response;
            })
        );
    }

    /**
     * Retreive Person Data
     * * @param validationcode
     * *@param data: Array
     * * @returns {Promise<any>}
     */
    validateInscription(data: any): Observable<any> {
        return this._eaHttpAbstract.post("/security/validate_inscription", {validation_code: data.code})
            .pipe(
                map(response=>{
                    return response;
                })
            );
    }
    inscriptionClientPassenger(data: any): Promise<any> {
        let headers = new HttpHeaders();
        let httpParams = new HttpParams();
        httpParams = httpParams.append('Param_Genre:', data.genre);
        httpParams = httpParams.append('Param_nom', data.lastname);
        httpParams = httpParams.append('Param_prenom', data.firstname);
        httpParams = httpParams.append('Param_phone', data.mobile);
        httpParams = httpParams.append('Param_Prefixe', data.prefix);
        httpParams = httpParams.append('Param_email', data.email);
        httpParams = httpParams.append('Param_password', data.password);
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/public/InscriptionClientPassager', httpParams,
                {
                    headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }

    validateInscriptionPassenger(data: any): Promise<any> {
        let headers = new HttpHeaders();
        let httpParams = new HttpParams();
        httpParams.append('Param_CodeSecurite:', data.code);
        return new Promise((resolve, reject) => {
            this._httpClient.post(this._url + '/public/ValidateInscriptionPassager', httpParams,
                {
                    headers: headers,
                    responseType: 'json',
                    withCredentials: true,
                    observe: 'response'
                }).subscribe((response: any) => {
                    resolve(response);
                },
                err => {
                    console.log('Error: ' + err.error);
                    console.log('Name: ' + err.name);
                    console.log('Message: ' + err.message);
                    console.log('Status: ' + err.status);
                }, reject);
        });
    }
    /**
    * Retreive Person Data
    * 
    * * @returns {Promise<any>}
    */
    socialAuthentication(data: any): Observable<any> {
        let _data: any = {}
        if(data.provider === 'GOOGLE') {
            _data.google_id = data.id;
            _data.type = 'google';
            _data.first_name = data.firstName;
            _data.email = data.email;
            _data.last_name = data.lastName;
            _data.picture = data.photoUrl;
            _data.id_token = data.idToken;
        } else if(data.provider === 'FACEBOOK') {
            _data.facebook_id = data.id;
            _data.type = 'facebook';
            _data.first_name = data.firstName;
            _data.email = data.email;
            _data.last_name = data.lastName;
            _data.picture = data.photoUrl;
            _data.id_token = data.authToken;
        }
        return this._eaHttpAbstract.post('/security/auth/social', _data);
    }
}
