import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
    FacebookLoginProvider, GoogleLoginProvider,
    SocialAuthService, SocialAuthServiceConfig, SocialLoginModule,
    SocialUser
} from "@abacritt/angularx-social-login";
import {Router, RouterLink} from "@angular/router";
import {SecurityService} from "../../security.service";
import {UtilsService} from "../../../../helpers/utils.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {STATUS_OK} from "../../../../app.constants";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {CommonModule} from "@angular/common";

declare global {
    interface Window {
        google: any;
    }
}
@Component({
    selector: 'app-auth-signin',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    imports: [
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        CommonModule,
        MatInputModule,
        SocialLoginModule,
        RouterLink
    ],
    standalone: true,
    providers : [
        SecurityService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider
                        ('239415642847-ui5ojj2mqe1s4n310vvgompi3v1sv73l.apps.googleusercontent.com')
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('426812791556548')
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        }
    ]
})
export class SignInComponent {
    @Output() nextStep = new EventEmitter<any>();
    @Input() typeAuth= '';
    errorAuthentication = false;
    rememberMe = false;
    public loginForm: FormGroup;
    public hide = true;
    socialUser : SocialUser;
    isLoggedin : boolean = undefined;
    user:any;
    errorMessage: string = "";
    constructor(
        public fb: FormBuilder,
        public router:Router,
        private _formBuilder: FormBuilder,
        private _securityService :  SecurityService,
        private _socialAuthService: SocialAuthService,
        private _utilsService : UtilsService,
        private snackBar: MatSnackBar,
    ) {}
    createFakeGoogleWrapper = () => {
        const googleLoginWrapper = document.createElement('div');
        googleLoginWrapper.style.display = 'none';
        googleLoginWrapper.classList.add('custom-google-button');
        document.body.appendChild(googleLoginWrapper);
        window.google.accounts.id.renderButton(googleLoginWrapper, {
            type: 'icon',
            width: '200',
        });
        const googleLoginWrapperButton = googleLoginWrapper.querySelector(
            'div[role=button]'
        ) as HTMLElement;

        return {
            click: () => {
                googleLoginWrapperButton?.click();
            },
        };
    };

    ngOnInit(): void {
        this.initLoginForm();
        this._socialAuthService.authState.subscribe((user) => {
            this.socialUser = user;
            this.isLoggedin = (user != null && user != undefined);
            this._securityService.socialAuthentication(this.socialUser).subscribe((res) =>{
                if(res?.status == STATUS_OK) {
                    this.user = res.data.data_user;
                    this._securityService.emitIsConnected(this.isLoggedin);
                    this._securityService.emitDataUser(this.user);
                    this._utilsService.setSession('userdata', this.user);
                    if(this.typeAuth == 'booking'){
                        this.nextStep.emit();
                    }else{
                        this.router.navigate(['/']);
                        // this.router.navigate(['/']).then(() => location.reload());
                    }
                } else {
                    return this.snackBar.open('Erreur de connexion, veuillez réessayer ultérieurement.', '×', {
                        verticalPosition: 'top',
                        duration: 3000,
                        panelClass: ['red-snackbar'],
                    });
                }
            });
        });
    }

    initLoginForm(){
        this.loginForm = this._formBuilder.group({
            username: this._formBuilder.control('',[ Validators.required, Validators.minLength(3)]),
            password : this._formBuilder.control('',[ Validators.required, Validators.minLength(5)]),
            rememberMe : this.rememberMe,
        });
    }

    authentication(){
        const username = this.loginForm.get('username').value;
        const password = this.loginForm.get('password').value;
        if (this.loginForm.valid) {
            this._securityService.authentication(username, password)
                .subscribe((response) => {
                    if (response.status === STATUS_OK) {
                        this.errorAuthentication = false;
                        if(this.typeAuth == 'booking'){
                            this.nextStep.emit();
                        }else{
                            this.router.navigate(['/']);
                        }
                    } else {
                        if(response?.message?.error_disabled_account) {
                            this.errorMessage = "Votre compte est désactivé";
                            this.snackBar.open(this.errorMessage, '×', {
                                verticalPosition: 'top',
                                duration: 5000,
                                panelClass: ['red-snackbar'],
                            });
                        }else {
                            this.errorMessage = "Nom d'utilisateur ou mot de passe invalides.";
                            this.snackBar.open(this.errorMessage, '×', {
                                verticalPosition: 'top',
                                duration: 5000,
                                panelClass: ['red-snackbar'],
                            });
                        }
                    }
                })
        }
    }
    signInWithFB(): any {
        this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userFacebbok =>{
            this._securityService.socialAuthentication(userFacebbok).subscribe(res =>{
                this._securityService.authenticated();
                this.router.navigate(['/']);
            });
        }).catch ( (error)=> {
            console.log(error);
        });
    }
    googleSignin() {
        this.createFakeGoogleWrapper().click();
    }
    protected readonly environment = environment;
}
